import React from "react";
import { Route, Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/login/login.component";
import Navbar from "components/navbar/navbar.component";

import Countries from "components/masters/geography/countries/countries.component";
import States from "components/masters/geography/states/states.component";
import Districts from "components/masters/geography/districts/districts.component";
import Citys from "components/masters/geography/citys/citys.component";
import Talukas from "components/masters/geography/talukas/talukas.component";
import Villages from "components/masters/geography/villages/villages.component";

import Fields from "components/masters/fields/fields.component";

import CompanyProductCategorys from "components/masters/cproduct/categorys/cpCategorys.component";
import CompanyProduct from "components/masters/cproduct/products/products.component";
import RdemptionProductCategorys from "components/masters/rproduct/categorys/rpCategorys.component";
import RedemptionProduct from "components/masters/rproduct/products/products.component";

import Company from "components/company/company.component";
import Configuration from "components/configuration/configuration.component";
import MasterProgram from "components/program/masterprogram.component";
import ViewProgram from "components/program/viewprogram.component";
// import ViewChildProgram from "components/program/viewchildprogram.component";
import CreateChildProgram from "components/program/createchildprogram.component";
import ManageCompany from "components/company/managecompany.component";
import { countryData } from "./components/countryList/countryList";

function App() {
  localStorage.setItem("masterprogramID", 1);

  // setInterval(() => {
  //   countryData();
  // }, 15000);

  // window.onbeforeunload = () => {
  //   localStorage.clear();
  // }
  return (
    <>
      {localStorage.getItem("userid") ? (
        <>
          <body class="g-sidenav-show  bg-gray-200">
            <Navbar />
            <Switch>
              {/* Geography */}
              <Route
                exact
                path="/masters/geography/countries"
                component={Countries}
              />
              <Route
                exact
                path="/masters/geography/states"
                component={States}
              />
              <Route
                exact
                path="/masters/geography/districts"
                component={Districts}
              />
              <Route exact path="/masters/geography/citys" component={Citys} />
              <Route
                exact
                path="/masters/geography/talukas"
                component={Talukas}
              />
              <Route
                exact
                path="/masters/geography/villages"
                component={Villages}
              />
              {/* Geography Ends */}
              {/* Fields */}
              <Route exact path="/masters/fields" component={Fields} />
              {/* Fields Ends */}
              {/* company products */}
              <Route
                exact
                path="/masters/cproduct/cpCategorys"
                component={CompanyProductCategorys}
              />
              <Route
                exact
                path="/masters/cproduct/cpProducts"
                component={CompanyProduct}
              />
              {/* company products Ends */}
              {/* Rdemption products */}
              <Route
                exact
                path="/masters/rproduct/rpCategorys"
                component={RdemptionProductCategorys}
              />
              <Route
                exact
                path="/masters/rproduct/rpProducts"
                component={RedemptionProduct}
              />
              {/* Rdemption products Ends */}
              {/* Company */}
              <Route exact path="/company" component={Company} />
              <Route
                exact
                path="/company/manage/:id"
                component={ManageCompany}
              />
              {/* Company Ends */}
              {/* Configuration */}
              <Route exact path="/configurations" component={Configuration} />
              {/* Configuration Ends */}
              {/* Company Master Programs */}
              <Route
                exact
                path="/company/masterprograms/:compid"
                component={MasterProgram}
              />
              <Route
                exact
                path="/viewprogram/:compid/:programid"
                component={ViewProgram}
              />
              <Route
                exact
                path="/createchildprogram/:compid/:programid"
                component={CreateChildProgram}
              />
              {/* Programs Ends */}
            </Switch>
          </body>
        </>
      ) : (
        <>
          <Route exact path="/login" component={Login} />
          <Route exact path="/" component={Login} />
        </>
      )}
    </>
  );
}

export default App;
